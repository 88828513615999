.ToggleFilterBtn {
    position: absolute;
    right: 20px;
    top: 10px;
    width: 40px;
    height: 40px;
    z-index: 2;
    font-size: 26px;
    background: #fff;
    cursor: pointer;
    border-radius: 7px;
    text-align: center;
}

.FiltersWrap {
    text-align: left;
}

.FiltersWrap button:first-child {
    margin-right: 10px;
}
.FilterPanelWrap {
    position: absolute;
    right: 0;
    top: 0;
    width: 18%;
}

.FilterPanelWrap input[type='checkbox'] {
    margin-bottom: -2px !important;
}

.FilterPanelWrap input[type='checkbox']:before {
    top: 0 !important;
    left: 4px !important;
    width: 5px !important;
    height: 6px !important;
}

.FilterPanelWrap input[type='checkbox']:after {
    width: 12px !important;
    height: 12px !important;
}

.DateWrap {
    width: 90%;
    margin: 10px auto;
    background: #fff;
    padding: 15px 15px 10px 15px;
    border-radius: 20px;
    font-size: 20px;
    border-bottom: 1px solid rgb(165, 165, 165);
}

.FiltersBtn {
    width: 40%;
    margin: 5px 15px;
    float: right;
    background: #fff;
    padding: 10px 6px 6px 6px;
    border-radius: 20px;
    font-size: 14px;
    border-bottom: 1px solid rgb(165, 165, 165);
    cursor: pointer;
    position: relative;
}

.CountAppliedFilters {
    background: red;
    border-radius: 100%;
    padding: 3px;
    line-height: 7px;
    position: absolute;
    right: -3px;
    top: -2px;
    color: #fff;
    font-size: 13px;
    z-index: 3;
}

.FiltersBtnInWrap {
    float: left;
    background: #fff;
    padding: 5px 0;
    font-size: 12px;
    cursor: pointer;
    margin-top: -5px;
}

.MuiFormControl-root {
    vert-align: middle !important;
}

.FiltersWrap {
    position: relative;
    margin: 10px 10px 0 25px;
    text-align: left;
}

.ActionBlock button {
    font-size: 9px;
    padding: 5px;
}

.ZonesWrap label {
    width: 80%;
    font-size: 12px;
    white-space: nowrap;
    height: 22px;
    overflow: hidden;
    display: inline-flex;
}
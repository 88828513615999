.RightPanelWrap {
    position: absolute;
    background: #fff;
    top: 0;
    right: 5px;
    transition: all .3s ease;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    min-width: 320px;
}

.RightPanelWrap::-webkit-scrollbar {
    display: none;
}

.RightPanelWrap.Closed {
    right: -100%;
}
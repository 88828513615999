body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTypography-body2, .MuiPickersCalendarHeader-dayLabel, .MuiTypography-body1 {
    font-size: 14px !important;
}

.placemark {
  background-size: 100%;
  background-repeat: no-repeat;
  width: 30px;
  height: 42px;
  position: absolute;
  left: -15px;
  top: -42px;
}

.placemark.selected {
  width: 40px;
  height: 40px;
  left: -20px;
}

.placemark.bounce {
  animation: bounce 1s ease infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-15%);
  }

  100% {
    transform: translateY(0);
  }
}

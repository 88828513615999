.BalloonBody {
    font-size: 14px;
    padding: 5px;
    text-align: left;
    min-width: 150px;
}

.EditIcon {
    padding: 0 5px;
}

.LabelInBalloon {
    display: inline-block;
    margin-bottom: 5px;
    padding: 5px;
}
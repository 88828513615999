.WaypointWrap {
    border: 2px solid #2196f3;
    margin: 5px 0;
    padding: 0 2px 2px 2px;
    border-radius: 3px;
    text-align: left;
    z-index: 1;
}

.WaypointName {
    font-size: 14px !important;
    margin-left: -2px;
    border-radius: 0 !important;
    margin-bottom: 15px;
    display: block !important;
    margin-right: -2px;
    background-color: #57b5ff !important;
    position: relative;
}

.RouteItemWrap {
    position: relative;
    margin: 0 -2px 0 -2px;
}

.RouteItemWrap.New {
    background: rgba(0,0,0,0.1);
}

.RouteItemWrap.Late {
    background-color: rgba(224,62,92,0.32);
}
.RouteItemWrap.Late.RouteItemIsActive {
    background-color: rgba(224, 62, 92, 0.45);
}

.RemoveRouteItemBtn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
}

.RouteItemInfo {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
}

.RouteItemIsActive {
    border: 1px solid rgb(27, 27, 27);
}
.DriveStatus {
    position: absolute;
    right: 2px;
    top: 0;
}
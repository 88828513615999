.WorkTime {
    width: 60%;
    float: left;
}

.RouteShortInfo {
    margin-top: 10px;
    text-align: left;
}

.CountDrives {
    width: 40%;
    float: right;
    text-align: right;
}

.EmployeeListWrap {

}

.RouteIsConfirmed {
    color: green;
}

.RouteIsNotConfirmed {
    color: red !important;
}

.RouteAlert {
    background-color: rgb(255, 202, 190) !important;
}

.RouteWarning {
    background-color: #fdff85 !important;
}

.RouteConfirm {
    margin-right: 4px;
    display: inline-block;
}

.LateDrivesWrap {
    display: inline-block;
    color: green;
}

.HasLateDrives {
    color: red !important;
}
.Icon, .SaveIcon {
    cursor: pointer;
    display: block;
}

.SaveIcon {
    color: green;
}

.EditableTextColumnContainer {
    padding: 0 !important;
    vertical-align: middle !important;
}

.EditableTextColumnRow {
    display: table !important;
    margin: 0;
}

.EditableTextColumnValue, .EditableTextColumnIcon {
    display: table-cell;
    vertical-align: middle !important;
}

.EditableColorColumnPreview {
    width: 29px;
    height: 30px;
    border-radius: 10px;
    cursor: pointer;
}

.EditableColorColumnPicker {
    position: absolute;
    top: 30px;
    background-color: white;
    z-index: 1;
    padding: 10px;
    border: 1px solid;
    border-radius: 10px;
}
.CentralPanel {
    position: absolute;
    top: 10px;
    width: 100%;
    font-size: 20px;
}

.CentralPanelBody {
    padding: 5px;
    margin: 0 auto 5px auto;
}

#CityDropdown, #CategoriesDropdown {
    display: inline-block !important;
}

.Dropdown{
    margin-right: 10px;
}

#CategoriesDropdown .DropdownWrap {
    width: 300px;
}

#CategoriesDropdown a {
    text-align: left !important;
    padding: 0 5px;
}

#CategoriesDropdown a:focus {
    outline: none;
}

.MenuButton {
    margin-right: 10px;
}

.MenuButtonWrap {
    position: relative;
    display: inline-block;
}

.MenuButton:focus {
    color: #444444 !important;
    outline: none !important;
}

.MenuButton:hover {
    color: #444444 !important;
}

.MenuButtonActive, .MenuButtonActive:focus {
    background-color: #2196f3 !important;
    outline: none;
    color: #fff;
}

.Dropdown a {
    display: block;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    color: #333;
    text-decoration: none;
}

.Dropdown a:hover {
    background-color: #f1f1f1;
    color: #333;
    text-decoration: none;
}

.CategoriesWrap {
    display: inline-grid;
    width: 200px;
    position: absolute;
    z-index: 0;
}

.CategoriesWrap span {
    background-color: #ececec;
    font-size: 12px;
    padding: 0 10px;
    display: inline-block;
    border-radius: 20px;
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
}

.CounterWrap {
    position: absolute;
    top: -8px;
    right: 6px;
    width: 30px;
    height: 30px;
    font-size: 12px;
}

.InternalCount {
    background: red;
    border-radius: 100%;
    padding: 3px;
    line-height: 9px;
    position: absolute;
    right: 7px;
    top: 2px;
    color: #fff;
    font-size: 13px;
}

.Count {
    padding: 15px;
    cursor: pointer;
    position: absolute;
    right: -5px;
    top: 3px;
}

.CounterContent {
    position: absolute;
    right: -475px;
    background-color: #ececec;
    z-index: 2;
    border-radius: 10px;
    color: #333;
    padding: 10px;
    width: 470px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 90vh;
}

.CounterContentRow:last-child {
    margin: 0;
}

.CounterContentRow:not(:last-child) {
    margin: 0 0 10px 0;
    padding-bottom: 5px;
    border-bottom: 1px solid grey;
}

.CounterContentItem {
    font-size: 9px;
    text-align: left;
}

.CounterContentItem span {
    color: grey;
}


.Status {
    color: #fff !important;
    padding: 0 3px;
    border-radius: 5px;
    display: inline-block;
}

.Window {
    background: #fff;
    position: absolute;
    border-radius: 5px;
    max-height: 90vh;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 5px;
    border-bottom: 1px solid rgb(165, 165, 165);
    border-left: 5px solid #7c7cff;
    z-index: 1;
}

.CloseIcon {
    top: 0;
    position: sticky;
    float: right;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
    color: #80808094;
    z-index: 2;
}

.CloseIcon:hover {
    color: rgba(73, 70, 70, 0.58);
}
.ContentBody {
    text-align: left;
    padding-left: 20px;
}

.UnBox {
    border-bottom: 1px solid #50a0ee;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.MoreHistory {
    text-align: center;
    background: #f3f3f3;
    font-size: 17px;
    padding: 5px 10px;
    width: 100%;
    display: block;
    margin-bottom: 10px;
    cursor: pointer;
}

.ScrollWrap {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 95vh;
}

.ScrollWrap::-webkit-scrollbar{
    display:none
}

.LogGroup {
    border: solid 3px #adadf9;
    padding: 5px;
    margin-top: 5px;
    border-radius: 5px;
}
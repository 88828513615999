.CentralPanel {
    position: absolute;
    top: 10px;
    left: 45%;
    font-size: 20px;
}

.CentralPanelSearchInput {
    vertical-align: middle !important;
}

.CentralPanelBody {
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
    width: 230px;
    margin: 0 auto 5px auto;
    position: relative;
}

.CentralPanelIconPointer {
    cursor: pointer;
}

.CentralPanelIcon {

}

.CentralPanelDropDownMenu {
    background: #ffffff;
    font-size: 12px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    position: absolute;
    width: 230px;
    left: 14px;
    top: 39px;
    display: none;
}

.CountCouriersWithLateDrives {
    font-size: 13px;
    text-align: center;
    display: inline-block;
    width: 100%;
    color: red;
    font-weight: 500;
}

.CentralPanelDropDownMenu.Active {
    display: block;
}

.CentralPanelDropDownMenu ul {
    list-style: none;
    padding: 0;
}

.CentralPanelDropDownMenu ul li {
    cursor: pointer;
    padding: 3px;
}

.CentralPanelDropDownMenu ul li span {
    position: relative;
}

.CentralPanelDropDownMenu ul li:hover {
    background: #dad7d7;
}

.HideClosableRightPanelBtn {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding-top: 47vh;
    color: #fff;
    width: 25px;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
    background: rgba(70,137,202,0.48);
}

.HideClosableRightPanelBtn:hover {
    background: #338ade;
}

.HasNotificationMenuIcon {
    top: 5px;
    right: -5px;
}

.HasNotificationIcon {
    position: absolute;
    color: red;
    font-size: 12px;
}

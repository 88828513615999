.DriveIndex {
    padding: 5px 15px;
    background: #c3c3c3;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #fff;
    font-weight: 700;
}

.DriveWrap {
    text-align: left;
    margin-bottom: 10px;
    margin-top: 10px;
}

.DriveTime {
    padding: 5px 15px;
}

.RouteWrap {
    text-align: left;
}

.RouteLength {
    background: green;
    border-radius: 100%;
    padding: 3px;
    line-height: 9px;
    position: absolute;
    right: 7px;
    top: 2px;
    color: #fff;
    font-size: 13px;
}
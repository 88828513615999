.BalloonBody {
    font-size: 12px;
    width: 200px;
}

.OrderNumber {
    background-color: #4caf50;
    padding: 3px;
    border-radius: 5px;
}

.OrderNumber a,
.OrderNumber a:hover,
.OrderNumber a:active,
.OrderNumber a:focus {
    color: #fff;
    text-decoration: none;
}

.Category {
    background-color: #e2e3e5;
}

.Category, .Status {
    color: #000000;
    padding: 0 3px;
    border-radius: 5px;
    margin-top: 5px;
    display: inline-block;
}

.Status {
    color: #fff !important;
}

.Time, .Address, .User, .Cleaner {
    font-size: 12px;
    display: inline-block;
    margin-top: 5px;
    color: #626262;
}

.ActionIcon {
    font-size: 16px;
    padding-top: 6px;
    color: #5e5c5c;
    cursor: pointer;
}

.CommentForm {
    background-color: #f5f5f5;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid rgb(165, 165, 165);
    z-index: 1;
}

.CommentForm textarea {
    background: #fff !important;
    resize: none;
    font-size: 12px !important;
}

.CommentCounter {
    position: absolute;
    top: 5px;
    right: 0;
    background: #2196f3;
    width: 10px;
    height: 10px;
    font-weight: 700;
    color: #fff;
    padding: 0 3px;
    border-radius: 100%;
    font-size: 8px;
    line-height: 1.4;
    cursor: pointer;
}
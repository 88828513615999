.WsWrap {
    position: absolute;
    left: 20%;
    z-index: 2;
    top: 0;
    width: 25%;
    max-height: 250px;
    overflow: hidden;
    overflow-y: auto;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(#000), to(rgba(0,0,0,0.7)));
}

.WsWrap::-webkit-scrollbar {
    display: none;
}

.Alert {
    width: 100%;
    display: block;
    margin: 3px 0 0 0 !important;
    text-align: left;
    position: relative;
}

.CloseBtn {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 16px;
    cursor: pointer;
}
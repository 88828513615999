.CitySelect {
    width: 100%;
}

.CitySelect a {
    display: inline-block;
    padding: 0 5px;
}

.CityBtn {
    border-radius: 20px !important;
    background-color: #f9f7f7 !important;
    color: #333;
}

.CityBtn:active, .CityBtn:hover, .CityBtn:focus, .CityBtn.Active {
    background-color: #b9babb !important;
    color: #333;
    outline: none !important;
}
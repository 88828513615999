.AddressInputComboContainer {
    position: absolute;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
    top: 0;

}
.AddressInputComboContainer.Collapsed {
    left: -60px;
}

.AddressInputComboContainer.Opened {
    left: -285px;
}

.AddressInputContainer {
    display: inline-block;
    margin-right: 5px;
    position: relative;
}

.AddressInput {
    width: 100%;
    min-width: 220px;
}
.AddressInput>input {
    font-size: 12px !important;
}

.SuggestItemsContainer {
    display: flex;
    position: absolute;
    top: 40px;
    left: -6px;
    width: 235px;
}

.SuggestItemsList {
    margin: 0;
    padding: 0;
    max-height: 292px;
    width: 100%;
    background-color: #fff;
    overflow-y: scroll;
    border-radius: 6px;
    border-top: 1px solid #f9f9f9;
    box-shadow: 0 6px 24px 0 rgb(0 0 0 / 8%);
}

.SuggestItem {
    cursor: pointer;
    border-top: 1px solid #f9f9f9;
    width: 100%;
    min-height: 32px;
    padding: 6px;
    font-size: 12px;
    line-height: 1.4;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.SuggestItem:hover {
    background-color: #f9f9f9;
}

.SuggestItem:first-child {
    border-top: 1px solid transparent;
}

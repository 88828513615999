.BlockLayout {
    position: absolute;
    width: 100%;
    height: 100vh;
    background: #fff;
    z-index: 100000;
    opacity: 0.5;
}

.BlockLayoutSpinner {
    display: flex;
    padding: 3px;
    position: absolute;
    left: 45%;
    z-index: 1000000;
    top: 35%;
}
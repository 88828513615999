.WorkTime {
    width: 100%;
    float: left;
    text-align: left;
    margin-top: 5px;
}

.WorkTime button {
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 10px;
}

.CountZones {
    font-size: 12px;
}
.EmployeeList {
    float: left;
    height: 100vh;
    overflow-x: scroll;
    position: absolute;
    z-index: 100;
    top: 0;
    font-size: 14px;
    left: 5px;
    padding-top: 60px;
    width: 20%;
    min-width: 380px;
}

.EmployeeList::-webkit-scrollbar {
    display: none;
}

.EmployeeEditIcon {
    font-size: 13px;
    vertical-align: top;
    margin-left: 10px;
    margin-top: 2px;
    display: inline-block;
}

.EmployeeIcon {
    width: 32px;
    height: 32px;
    background: url("/public/icon/employee.png");
    float: left;
    margin-top: 2px;
}

.EmployeeListFilter {
    background: #fff;
    margin: 5px 5px 5px 0;
    padding: 10px;
    border-radius: 10px;
    position: fixed;
    width: calc(18vw);
    top: 0;
    z-index: 1;
}

@media (min-width: 1380px) {
    .EmployeeListFilter {
        width: calc(18vw);
    }
}

@media (max-width: 1379px) {
    .EmployeeListFilter {
        width: 300px;
    }
}

.EmployeeName {
    top: 0;
    left: 40px;
    font-size: 18px;
}

.EmployeePhone {
    top: 16px;
    left: 40px;
    font-size: 14px;
}

.EmployeeItemHead {

}

.EmployeeItemHeadLeft {
    position: relative;
}

.EmployeeItemHeadRight {
    position: relative;
    float: right;
}

.EmployeeName, .EmployeePhone {
    position: absolute;
    margin: 0;
    font-family: 'Roboto Light', serif;
}

.EmployeeItem {
    padding: 10px;
    position: relative;
    width: 90%;
    border-bottom: 1px solid rgb(165, 165, 165);
    cursor: pointer;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 5px;
}

.EmployeeItem.Active {
    margin: 20px 0;
    width: 100%;
    border-left: 5px solid #7c7cff;
}

.EmployeeItem:hover {
    background-color: rgb(241 240 240);
}

.ZoneEditor {
    padding: 20px;
    overflow: scroll;
    height: 100vh;
}

.Label {
    text-align: left;
    margin-bottom: 10px;
}

.ActionIcon {
    cursor: pointer;
}

.CloseIcon {
    color: red;
    cursor: pointer;
}

.AddMapIcon {
    color: green
}

.AddMapIconContainer {
    cursor: pointer;
    text-align: left;
    width: 20%;
}

.CategoryLabel {
    padding: 5px;
    display: inline-block;
    background-color: #ededed;
    border-radius: 5px;
    border: 1px solid;
    font-size: 14px;
    margin: 3px;
}

.DrawContainer {
    position: fixed;
    height: 100vh;
    top: 0;
    right: 0;
}

.DraftIsPublished {
    color: green;
}

.DraftIsNotPublished {
    color: red;
}
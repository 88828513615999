.ContentBody:not(:last-child) {
    border-bottom: 1px solid #80808094;
}

.ContentBody {
    font-size: 12px;
}

.DriveTypeLabel {
    width: 90%;
    font-size: 14px;
    margin-top: 10px;
}

.ClientCrown {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 20px;
    top: 0;
}

.ExpandDriveTypeLabel {
    width: 50% !important;
}

.OpenAdditionalInfoBtn {
    font-size: 20px;
    margin-top: -8px;
    cursor: pointer;
    float: right;
}

.EditWaypointBtn {
    cursor: pointer;
    color: blue;
}